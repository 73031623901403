import React from 'react';
// import ReactMarkdown from 'react-markdown';
// import content from './Home.content';
import classes from './Home.module.scss';

function Home() {
  return (
    <div className={classes.container}>
      <p className={classes.centerText}>portfolio site coming soon</p>
      {/* <ReactMarkdown className={classes.markdown} source={content} /> */}
    </div>
  );
}

export default Home;
