import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  TILT_BOUND_LOWER,
  TILT_BOUND_UPPER,
  SHIFT_BOUND_LOWER,
  SHIFT_BOUND_UPPER,
} from './Background.constants';
import { primaryColor } from '../../constants/styles';
import LogoSvg from '../LogoSvg';
import { getBoundedRandomInt } from '../../utils';

function Background({ className }) {
  const svgPropsArray = [];
  for (let i = 2; i <= 100; i += 10) {
    for (let j = 2; j <= 100; j += 10) {
      const tilt = getBoundedRandomInt(TILT_BOUND_LOWER, TILT_BOUND_UPPER);
      const topShift = getBoundedRandomInt(SHIFT_BOUND_LOWER, SHIFT_BOUND_UPPER);
      const leftShift = getBoundedRandomInt(SHIFT_BOUND_LOWER, SHIFT_BOUND_UPPER);
      const config = {
        top: i,
        left: (i - 2) % 20 ? j + 5 : j,
        size: 20,
        tilt,
        topShift,
        leftShift,
      };
      svgPropsArray.push(config);
    }
  }
  return (
    <div className={className}>
      {
        map(svgPropsArray, ({
          top, topShift, left, leftShift, tilt, size,
        }, index) => (
          <LogoSvg
            key={`logo-svg-${index}`}
            style={{
              position: 'absolute',
              top: `calc(${top}% + ${topShift}px)`,
              left: `calc(${left}% + ${leftShift}px)`,
              transform: `rotate(${tilt}deg)`,
            }}
            color={primaryColor}
            opacity={0.3}
            size={`${size}px`}
          />
        ))
      }
    </div>
  );
}

Background.propTypes = {
  className: PropTypes.string,
};

export default Background;
