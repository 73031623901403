import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Project from '../Project';
import { projects } from './Projects.data';
import classes from './Projects.module.scss';

function Projects() {
  return (
    <div className={classes.container}>
      Here is an assortment of projects I've built in the last few years.
      <div className={classes.projects}>
        {map(projects, (p) => <Project {...p} />)}
      </div>
    </div>
  );
}

Projects.propTypes = {
  data: PropTypes.object,
};

export default Projects;
