import React from 'react';
import ReactMarkdown from 'react-markdown';
import GrannySquaresLetterhead from '../../../../static/granny-squares/gs-letterhead.png';
import content from './Hosting.content';
import classes from './Hosting.module.scss';

function Hosting() {
  return (
    <div className={classes.container}>
      <ReactMarkdown className={classes.markdown} source={content} />
      <div>
        <h4>Granny Squares</h4>
        <div className={classes.group}>
          <img src={GrannySquaresLetterhead} className={classes.img} alt="Granny Squares project letterhead" />
        </div>
      </div>
    </div>
  );
}

export default Hosting;
