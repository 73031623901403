import React from 'react';
import PropTypes from 'prop-types';
import classes from './Project.module.scss';

function Project({
  name, description, image, url,
}) {
  return (
    <div className={classes.container}>
      <a href={url} className={classes.link}>
        <h2 className={classes.header}>{name}</h2>
        <img src={image} alt={name} className={classes.image} />
      </a>
      <p className={classes.textBlock}>{description}</p>
    </div>
  );
}

Project.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Project;
