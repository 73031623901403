import HomeRoute from './Home';
import ContactRoute from './Contact';
import ProjectsRoute from './Projects';
import ServicesRoute from './Services';
import TestomonialsRoute from './Testomonials';
import HostingRoute from './Hosting';

export default [
  HomeRoute,
  // ProjectsRoute,
  // ServicesRoute,
  // TestomonialsRoute,
  // ContactRoute,
  HostingRoute,
];
