import { compose, withProps } from 'recompose';
import avatar from '../../static/avatar-bw-512.png';

export default compose(
  withProps({
    headerProps: {
      name: 'Lizzie Paris',
      imageUrl: avatar,
      minimized: false, // TODO get from route
    },
  }),
);
