export const TILT_BOUND_LOWER = -40;
export const TILT_BOUND_UPPER = 40;
export const SHIFT_BOUND_LOWER = -10;
export const SHIFT_BOUND_UPPER = 10;

export default {
  TILT_BOUND_LOWER,
  TILT_BOUND_UPPER,
  SHIFT_BOUND_LOWER,
  SHIFT_BOUND_UPPER,
};
