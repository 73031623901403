import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
// import NavLinks from '../../components/NavLinks';
import Background from '../../components/Background';
import classes from './CoreLayout.module.scss';

function CoreLayout({ headerProps, children }) {
  return (
    <div className={classes.container}>
      <Background className={classes.background} />
      <div className={classes.centerColumn}>
        <div className={classes.header}>
          <Header {...headerProps} />
          {/* <NavLinks /> */}
        </div>
        {children}
      </div>
    </div>
  );
}

CoreLayout.propTypes = {
  headerProps: PropTypes.object,
  children: PropTypes.object,
};

export default CoreLayout;
