import React from 'react';
import PropTypes from 'prop-types';

function LogoSvg({
  style, color, opacity, size,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      style={style}
    >
      <path
        id="Selection"
        fill={color}
        stroke={color}
        opacity={opacity}
        strokeWidth="1"
        d="M 152.00,112.00
             C 152.00,112.00 152.00,373.00 152.00,373.00
               152.00,373.00 198.00,373.00 198.00,373.00
               198.00,373.00 198.00,112.00 198.00,112.00
               198.00,112.00 152.00,112.00 152.00,112.00 Z
             M 279.00,211.00
             C 279.00,211.00 276.00,188.00 276.00,188.00
               276.00,188.00 238.00,188.00 238.00,188.00
               238.00,188.00 238.00,464.00 238.00,464.00
               238.00,464.00 275.00,464.00 275.00,464.00
               275.00,464.00 282.40,462.98 282.40,462.98
               282.40,462.98 284.00,457.00 284.00,457.00
               284.00,457.00 284.00,440.00 284.00,440.00
               284.00,440.00 284.00,361.00 284.00,361.00
               297.45,373.82 312.58,378.79 331.00,379.00
               359.32,379.32 383.16,365.86 399.57,343.00
               409.59,329.05 416.79,308.26 417.00,291.00
               417.21,273.15 417.61,262.56 412.57,245.00
               405.33,219.72 385.66,197.19 361.00,187.81
               354.11,185.20 344.36,183.09 337.00,183.00
               318.80,182.79 305.29,185.11 291.01,197.44
               291.01,197.44 279.00,211.00 279.00,211.00 Z
             M 321.00,224.29
             C 351.34,221.45 370.66,246.52 371.00,275.00
               371.00,275.00 371.00,285.00 371.00,285.00
               370.90,305.69 359.83,326.38 340.00,334.17
               333.72,336.64 328.64,337.07 322.00,337.00
               306.14,336.82 290.11,326.61 285.44,311.00
               283.25,303.69 284.00,292.85 284.00,285.00
               284.00,262.33 281.21,239.93 306.00,228.32
               311.49,225.75 315.15,225.18 321.00,224.29 Z"
      />
    </svg>
  );
}

LogoSvg.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  opacity: PropTypes.number,
  size: PropTypes.number,
};

export default LogoSvg;
