import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import HomeRoute from '../../routes/Home';
import isMobile from '../../utils/browser';
import classes from './Header.module.scss';

function Header({ name, imageUrl, minimized }) {
  return (
    <Link to={HomeRoute.path}>
      <div className={classes.container}>
        <img
          src={imageUrl}
          alt={name}
          className={classnames({
            [classes.avatar]: true,
            [classes.avatarMin]: minimized || isMobile,
          })}
        />
        <h1 className={classes.headerText}>{name}</h1>
      </div>
    </Link>
  );
}

Header.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default Header;
