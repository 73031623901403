// Color palette
export const black = '#111';
export const white = '#fefefe';

export const primaryColor = 'darkslategrey';
export const secondaryColor = 'peachpuff';
export const backgroundColor = 'honeydew';
export const textColor = black;

// border variables
export const thickBorder = '8px';
export const roundedCorners = '8px';
export const circularBorder = '1000px';

export default {
  black,
  white,
  primaryColor,
  secondaryColor,
  backgroundColor,
  textColor,
  thickBorder,
  roundedCorners,
  circularBorder,
};
