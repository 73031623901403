import React from 'react';
import classes from './Testomonials.module.scss';

function Testomonials() {
  return (
    <div className={classes.container}>
      <header>
        This is Testomonials
      </header>
    </div>
  );
}

export default Testomonials;
