import cipherImage from '../../../../static/cipher.png';

export const projects = {
  'cipher-decoder': {
    name: 'Cipher Decoder',
    description: 'A small collection of cipher decoders I like to use during puzzle hunts, open-note escape rooms, etc.',
    url: 'https://lzzparis.github.io/cipher-decoder',
    image: cipherImage,
  },
};

export default {
  projects,
};
